<script>
import VueContentLoading from 'vue-content-loading';

export default {
  components: {
    VueContentLoading,
  },
};
</script>

<template>
  <div>
    <vue-content-loading :width="300" :height="39">
      <rect x="0" y="0" width="400" height="39" />
    </vue-content-loading>
  </div>
</template>